window.onload = function() {
    /***/
    $(".mascota").click();
    /*transformo % a pixeles*/
    $(".after .logo").width($(".before .logo").width() - $(".before .logo").width() * 0.1);
    $(".mascota").width($(".mascota").width());
    $(".after .header").width($(".before").width());
    $(".after .mobileHide .contenedorLogo").width($(".before .contenedorLogo").width());
    $(".after .w3-right").width($(".before .w3-right").width());
    $(".after .mobileShow").width($(".before .mobileShow").width());
    $(".after .mobileHide").width($(".before .mobileHide").width());
    $(".after .textoHeader").width($(".after .textoHeader").width());
    $(".after").width("0px");
    $("footer").height($("footer").height());
    $(".mascota").css("top", $(".mascota").position().top);
    $(".icon-drag").css("top", $(".icon-drag").position().top);
    $(".after .mascota").css("left", $(".before .mascota").position().left);
    $(".after .mascota").css("top", $(".before .mascota").position().top);
    $(".after #maq_escribir").css("top", $(".before #maq_escribir").css("top"));
    $(".after #maq_escribir").css("left", $(".before #maq_escribir").css("left"));
    //Animacion inicial drag
    $('.after').animate({
        width: $(".before").width() / 13
    }, 1000);
    $("#draggable").animate({
        left: $(".before").width() / 13
    }, 1000);

    //configuracion inicial drag y captura de evento
    $("#draggable").css("left", $(".after").width());

    $("#draggable").draggable({ axis: "x", containment: 'parent' });
    $("#draggable").on("drag", function() {
        if ($(".after").width() > $(".before").width() / 2) {
            $(".after").width($("#draggable").position().left + 1);
        } else {
            $(".after").width($("#draggable").position().left - 1);
        }

    });

    //Maquina de escribir
    // $(function() {
    //     $("#typed").typed({
    //         strings: LABELS,
    //         typeSpeed: 40,
    //         backDelay: 700,
    //         loop: true,
    //         // defaults to false for infinite loop
    //         loopCount: false,
    //         callback: function() { foo(); }
    //     });

    //     function foo() { console.log("Callback"); }

    // });
    // $(function() {

    //     $("#typed2").typed({
    //         strings: LABELS,
    //         typeSpeed: 40,
    //         backDelay: 700,
    //         loop: true,
    //         // defaults to false for infinite loop
    //         loopCount: false,
    //         callback: function() { foo(); }
    //     });

    //     function foo() { console.log("Callback"); }

    // });

    /*** Animacion ***/
    $('.aniview').AniView();


    /*equipo*/
    var maxHeight = 0;

    $(".itemEquipo").each(function() {
        if ($(this).height() > maxHeight) {
            maxHeight = $(this).height() + 15;
        }
    });
    $(".itemEquipo").height(maxHeight);

    $(".header .lenguaje img").click(function() {
        $(".button").removeClass("active");
        $(".sidebar").removeClass("visibleSidebar");
    });




    /* MENU MOBILE */
    function toggleSidebar() {
        $(".button").toggleClass("active");
        $(".sidebar").toggleClass("visibleSidebar");
        $(".sidebar-item").toggleClass("active");
    }

    $(".button").on("click tap", function() {
        toggleSidebar();
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            toggleSidebar();
        }
    });
    /************/

    /**Nosotros mobile**/
    $(".itemEquipo").click(function() {
        if ($(document).width() < 450) {
            if (!$(this).hasClass("rotate")) {
                var element = $(this);
                $(element).removeClass("rotateBack");
                $(element).find("img").fadeOut("slow", function() {
                    $(element).find("h5").addClass("rotate");
                    $(element).find("h5").fadeIn("slow")

                });
                $(element).find("h3").addClass("rotate");
                $(element).addClass("rotate");
            } else {
                var element = $(this);
                $(element).find("h5").fadeOut("slow", function() {
                    $(element).find("img").addClass("rotateBack");
                    $(element).find("img").fadeIn("slow")

                });
                $(element).removeClass("rotate");
                $(element).addClass("rotateBack");
            }


        }
    });
    /***/

    /** Ir arriba**/
    $('.ir-arriba').click(function() {
        $('body, html').animate({
            scrollTop: '0px'
        }, 1000);
    });

    $(window).scroll(function() {
        var hT = $('.contacto').offset().top;
        var hH = $('.contacto').outerHeight();
        var wH = $(window).height();
        if ($(this).scrollTop() > 0 && $(this).scrollTop() < (hT + hH - wH)) {
            $('.ir-arriba').slideDown(300);
        } else {
            $('.ir-arriba').slideUp(300);
        }
    });
    /****/

}
var validate = { text: /^[a-zA-Z]{1,40}$/, email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ };

function sendContact() {
    var data = $("form").serializeArray();

    $("#message").text('sending...');
    $.post("contactoAction.php", { data: data })
        .done(function(respuesta) {
            if (respuesta) {
                $("#message").text(SUCCESS);
                $("#message").css("color", "green");
                $("#message").fadeIn("slow");
            } else {
                $("#message").text(ERROR)
                $("#message").css("color", "red");
                $("#message").fadeIn("slow");
            }
        });

}

function validateForm(id, captcha = "false") {
    var rdo;
    var nombre;

    if (captcha != "false" && captcha.length == 0) {
        nombre = "captcha";
        return nombre;
    }
    $("#" + id + " input").each(function() {
        nombre = $(this).attr("name");
        var tipo = $(this).attr("type");
        var valor = $(this).val();
        rdo = valor.match(validate[tipo]);
        if (!rdo) {
            return false;
        }
    });

    if (rdo) {
        return "true";
    } else {
        return nombre;
    }


}